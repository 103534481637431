@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;600&display=swap');

body {
  font-family: 'Fira Code', monospace;
}

.no-scrollbar::-webkit-scrollbar {
    display: none; 
}

.no-scrollbar {
    -ms-overflow-style:none;
    scrollbar-width: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.card-container {
  perspective: 1000px;
  -webkit-perspective: 1000px; /* Safari */
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s; /* Safari */
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; /* Safari */
}

.card:hover {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari */
}

.card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
}

.card-front,
.card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  position: absolute;
  width: 100%;
  height: 100%;
}

.card-back {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari */
}
